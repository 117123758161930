import './App.css';
import { Fragment } from 'react';
import Home from './Components/Home/Home';

function App() {
  return (
   <Fragment>
    <Home/>
   </Fragment>
  );
}

export default App;
