import React, { Fragment } from 'react'
import "./Home.css"
import Logo from "../../Assets/logo.png"
import email from "../../Assets/Icons/email.png"
import clock from "../../Assets/Icons/clock.png"
import gps from "../../Assets/Icons/gps.png"
import call from "../../Assets/Icons/call.png"

export default function Home() {
  return (
    <Fragment>
             <nav className='InfoNavHomeContainer'>
    <p  ><img className='NavIcon' src={gps}/>Panama City</p> <p><img src={clock} className='NavIcon'  />Lun - Lun</p> <p><img src={email} className='NavIcon' />gerencia@truckcenterpty.com</p> 
    <p className='HomePhone'><img className='NavIcon' src={call}/>+507 394 3336</p></nav>

        <section className='HomePrincipalContainer'>
       
         <section className='HomeContainer'>
            <img src={Logo} className="TruckLogo"/>
            <div className='HomeInfoContainer'>
                <h1>Web Site en Remodelacion !</h1>
                <div className='MobileEmail'><img src={email}  />Gerencia@truckcenterpty.com</div>
                <p>Con años de experiencia en la industria de neumaticos, nos especializamos en proveer las mejores opciones de llantas para una amplia variedad de carga pesada y maquinarias. Nuestra misión es ofrecer soluciones en neumaticos confiables y de calidad a precios competitivos, para que nuestros clientes puedan maximizar su inversión en transporte.</p>
                <a href='https://wa.link/w5ncry' target="_blank">WhatsApp</a>
            </div>
         </section>

        </section>

    </Fragment>
  )
}
